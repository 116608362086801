.ServiceCtnr {
    display: flex;
    flex-direction: column;
}
.classic{
    font-size: large;
    font-weight: bold;
    background-color: rgb(255, 255, 255, 65%);
    border: white 3px solid
}


.Titleh2 {
    font-size: 25px;
    font-weight: 300;
}

.Title {
    font-size: 25px;
    font-weight: bold;
}

.flex {
    display: flex;

    width: 100%;
    flex-direction: column;
    align-items: center;
}

.flex2 {
    background-color: rgb(255, 255, 255, 65%);
    border: #044b88 4px solid;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 61;
    width: 30%;
    overflow-y: scroll;
    height: 100%;
}

.flex3 {
    background-color: rgb(255, 255, 255, 65%);
    border: #044b88 4px solid;
    position: absolute;
    top: 0;
    left: 177px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 61;
    width: 22%;
    height: 100%;
    overflow-y: scroll;
}
.selectZone { 
  width: 70%;
  margin: 5px;
  margin-left: 35px;
  display: flex;
  flex-direction: row;
}
.Button {
    background-color: transparent;
    color: #044b88;
    font-weight: bold;
    font-size: large;
    border: #044b88 2px solid;
    border-radius: 20em;
}
.number{
  width: 7%;
}
.phone-selector{
  width: 40%;
}
.phone-selector-min{
  width: 40%;
}
.plus-resizer{
  width: 4%;
}
.Button:active {
    background-color: #044b88;
    color: white;
    font-size: large;
    font-weight: bold;
    border: #044b88 2px solid
}

.plus {
    z-index: 1;
}

.Imgstyle {
    width: 60%;
}

.leftalign {
    align-self: flex-start;
    left: 5px;
    z-index: 63;
    top: 20px;
}

.rightalign {
    align-self: flex-end;
    right: 0;
    z-index: 63;
    top: 0;
}

.mid {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalbtn {
    position: absolute;
    top: 0;
    right: 0;
    width: 4%;
}
.just_row{
    display: flex;
    flex-direction: row;
}
select {
    background-color: #044b88;
    color: white;
    border: #044b88 3px solid;
    border-radius: 20em;
    border: none;
    height: 25px;
    padding: 0 1em 0 0;
    margin: 2px;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
}

input {
    background-color: #044b88;
    color: white;
    border: #044b88 3px solid;
}

option {
    border: #044b88 3px solid;
    border-radius: 20em;
}

input {
    border: 2px solid #044b88;
}

p {
    align-self: center;
    max-width: 90ch;
    font-weight: bold;
    color: #044b88;
}

center {
    align-self: center;
}
/*checkbox
*/
:root {
  --form-control-color: #044b88;
  --form-control-disabled: #959495;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

form {
  display: grid;
  place-content: center;
  min-height: 100vh;
}
.check{
    position: relative;
    top: 6px;
    left: 14px;
}
.form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}
.form-control + .form-control {
  margin-top: 1em;
}

.form-control--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
background-color: white;
  font: inherit;
  color: #044b88;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #044b88;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid #044b88;
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}