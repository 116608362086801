.backcard {
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 1px;
    margin-bottom: 1px;
    height: 425px;
    width: 465px;
    text-align: center;
    align-items: center;
    border: 3px solid #004b88;
    border-radius: 30px;
}
.frontcardimg{width: 435px;}
.twoSideconf{
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}
.frontcardimg_spe{
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 1px;
    margin-bottom: 1px;
    height: 345px;
    text-align: center;
    align-items: center;
}
.frontcard {
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 1px;
    margin-bottom: 1px;
    width: 33%;
    height: 425px;
    text-align: center;
    align-items: center;
}

.Cards{
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
    display: flex;}
    
.Cards ::-webkit-scrollbar {
    display: none;
}
.twoSide{
    width: 100vh;
    display: flex;
    justify-content: center;
    height: 50%;
}
.twoSideconf-spe{
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    right: 10px;
}
@media screen and (max-width: 500px) {
    
}