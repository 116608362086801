.Cards {
    position: relative;
}

.widget {
    background: rgb(255, 255, 255, 95%);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 9px;
    padding: 30px;
    overflow: hidden;
    border-radius: 20px;
}

.row_1 {
    margin-top: 30px;
    margin-bottom: 30px !important;
}

.Spacing {
    height: 8vh;
}

.description {
    text-align: center;
    font-weight: bold;
}

.pic {
    height: 200px;
}

.footer {
    color: white;
}

h1 {
    font-size: 24px;
    font-weight: 800px;
    color: #004B88;
    font-family: 'Archivo Black', sans-serif;
    text-align: center
}

p {
    font-size: 16px;
    font-family: 'Poppins';
}


.button-style {
    background-color: #004B88;
    color: white;
    font-family: 'Archivo Black', sans-serif;
    font-size: 16px;
    border-radius: 20px;
    border: none;
    margin-top: 10px;
    padding-top: 8px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 8px;
    text-align: center;
    margin: 0;
}

.button-connect {
    background-color: #004B88;
    color: white;
    font-family: 'Archivo Black', sans-serif;
    font-size: 16px;
    border-radius: 60px;
    border: none;
    padding-left: 30px;
    padding-right: 30px;
    height: 50px;
    margin-top: 20px;
    margin-right: 10px;
    text-align: center;


}

.playlist {
    margin-top: 25px;
    text-align: center;
    float: center;
    margin-bottom: 30px;
}

.center-contain {
    text-align: center;
}

.bi {
    color: #004B88;
    font-size: 24px;
    margin-bottom: 15px;
}

.icon-center {
    text-align: center;

}

body {
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    animation: gradient 15s ease infinite;
    background-color: #004B88;
    background-size: 400% 400%;
    background-attachment: fixed;
}

.Background {
    color: #999999;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }

    50% {
        background-position: 100% 100%;
    }

    100% {
        background-position: 0% 0%;
    }
}

.wave {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 15vh;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.wave:nth-of-type(2) {
    bottom: -1.14em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
}

.wave:nth-of-type(3) {
    bottom: -2.7em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
}

@keyframes wave {
    2% {
        transform: translateY(1);
    }

    25% {
        transform: translateY(-5%);
    }

    50% {
        transform: translateY(-9%);
    }

    75% {
        transform: translateY(-15%);
    }

    100% {
        transform: translateY(1);
    }
}

.bg {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background: transparent url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
    background-repeat: repeat;
    animation: bg-animation .2s infinite;
    opacity: .9;
    visibility: visible;
}

@keyframes bg-animation {
    0% {
        transform: translate(0, 0)
    }

    10% {
        transform: translate(-5%, -5%)
    }

    20% {
        transform: translate(-10%, 5%)
    }

    30% {
        transform: translate(5%, -10%)
    }

    40% {
        transform: translate(-5%, 15%)
    }

    50% {
        transform: translate(-10%, 5%)
    }

    60% {
        transform: translate(15%, 0)
    }

    70% {
        transform: translate(0, 10%)
    }

    80% {
        transform: translate(-15%, 0)
    }

    90% {
        transform: translate(10%, 5%)
    }

    100% {
        transform: translate(5%, 0)
    }
}