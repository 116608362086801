h1 {

    font-family: LexendDecaMedium;
    color: #004B88;
    text-align: center;
}

h3 {

    font-family: LexendDeca;
    color: #0065B7;
    text-align: center
}

.wht {
    color: white;
}
.ServiceCtnr{
    height: 90vh;

    width: 74.1vw;
    margin-left: 17vw;
    overflow: scroll;
}
.spacer{
    height: 1200px;
}
.text{
    margin-top: 90px;
}
.content{
    background-image: url(/src/Components/img/ResizeCover.png);
    background-size:contain;
    background-attachment: scroll;
    background-repeat:repeat-y space;
    width: 102%;
}
.thk {
    height: 190px;
    align-items: center;
}

.resz {
    height: 12vh;
    z-index: 2;
}

h5 {
    font-family: LexendDeca;
    color: #0065B7;
    size: 23px;
}

.icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 52vw;
    width: 100vw;
    max-width: 100%;
}
.ficon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 60vw;
    width: 100%;
    max-width: 100%;
}

.spe {
    width: 80px;
}

.logs {
    width: 67px;
}

.ico {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px;
    width: 33%;
    margin-left: 30px;
    margin-right: 30px;
}

.pad {
    margin-top: 7px;
}

.anime {
    animation: animation 10s;
  animation-iteration-count:infinite;
}

.flow {
    position: absolute;
    z-index: 1;
    top: 4%;
    left: 35%;
    width: 30%;
    justify-content: center;
}
.unify{
    width: 650px;
}

@media (min-width: 300px) and (max-width: 630px) {
.icon {
    margin-left: 16vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 52vw;
    width: 50vw;
    max-width: 100%;
}
}

@keyframes animation {
    0% {
        width: 30%;
        opacity: 0;
    }

    50% {
        width: 30%;
        opacity: 0.7;
    }
    100% {
        width: 5%;
        opacity: 0;
    }

}
