.ServiceCtnr {
    align-items: center;
    flex-direction: column;
}
.ligne{
    width:28%;
    left: 2.5%;
}
.joiner{
    width: 100%;
    display: flex;
    align-items: center;
}
.contain{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgSPE{
    width: 13%;
}
.Titleh2{
    font-size: 25px;
    font-weight: 300;
}
.Title{
    font-size: 25px;
    font-weight: bold;
}
.speH{
   align-items: center;
   max-width: 1100px;
}
