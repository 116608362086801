.middle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 70px;
  justify-content: space-between;
  color: #044b88;
}

.menu,
.menuDevis {
  position: relative;
  color: #044b88;
  z-index: 6;
  height: 60px;
  text-align: center;
  border-radius: 8%;
  border: 2px solid transparent;
  background-color: transparent;
  transition: color 0.5s;
}

.menuDevis {
  margin-left: 60px;
}

.menu,
.menuDevis {
  font-weight: bold;
  font-size: 20px;
  border: 2px solid transparent;
  border-radius: 3/2em;
}

.menu::before,
.menuDevis::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: scaleX(0);
  transform-origin: bottom;
  transition: transform 0.5s, background-color 0.5s;
}

.menu:hover::before,
.menuDevis:hover::before {
  transform: scaleX(1);
  background-color: #044b88;
}

.menuDevis {
  color: #044b88;
}

.menuDevis:hover::before {
  background-color: #044b88;
}

.header {
  position: relative;
  background-color: white;
  padding: 5px;
  width: 100%;
  height: 86px;
  display: flex;
  justify-content: right;
  z-index: 6;
}

.mainContainer {
  height: 70px;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
}

.img {
  width: 130px;
  position: relative;
  z-index: 6;
  top: 5px;
}

.telimg {
  position: relative;
  max-height: 85px;
  z-index: 5;
  border-radius: 50%;
  border: 2px solid white;
  transition: border-radius 0.5s, border-color 0.5s, color 0.5s, background-color 0.5s;
}

.telimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.telimg:hover::before {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 5px 10px;
  border-radius: 8%;
  white-space: nowrap;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.5s;
}

.telimg:hover {
  border: 2px solid #044b88;
  color: #044b88;
  background-color: white;
}

.telimg.hovered {
  background: white;
  border: 2px solid #044b88;
  color: #044b88;
}

.Bcon {
  margin-right: 30px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: white;
  color: #c01c69;
  border: #c01c69 solid 3px;
  border-radius: 5px;
}

@media (min-width: 330px) and (max-width: 910px) {
  .mainContainer {
    height: 40px;
  }

  .middle {
    padding-left: 0;
  }

  .header {
    width: 100%;
    height: 40px;
    padding: 1px;
  }

  .img {
    width: 54px;
    position: relative;
    z-index: 6;
    top: 0;
  }

  .menu,
  .menuDevis {
    margin: 1px;
    font-size: 10px;
    height: 30px;
    top: -4px;
    width: 14vw;
  }

  .telimg {
    margin-top: -3px;
    right: -6px;
    height: 40px;
  }

  .middle {
    width: 56vw;
  }
}

@media (min-width: 910px) and (max-width: 1200px) {
  .mainContainer {
    height: 40px;
  }

  .middle {
    padding-left: 0;
  }

  .header {
    width: 100%;
    height: 40px;
    padding: 1px;
  }

  .img {
    width: 54px;
    position: relative;
    z-index: 6;
    top: 0;
  }

  .menu,
  .menuDevis {
    margin: 1px;
    font-size: 10px;
    height: 30px;
    top: -4px;
    width: 14vw;
  }

  .telimg {
    margin-top: -3px;
    right: -6px;
    height: 40px;
  }

  .middle {
    width: 56vw;
  }
}

@media (min-width: 1200px) and (max-width: 1309px) {
  .mainContainer {
    height: 70px;
  }

  .middle {
    padding-left: 0px;
  }

  .header {
    width: 100%;
    height: 80px;
  }

  .img {
    width: 114px;
    top: 2%;
    position: relative;
    z-index: 6;
  }

  .menu {
    margin-right: 3vw;
    margin-left: 4vw;
    font-weight: bold;
    font-size: 18px;
    border: 2px solid white;
    border-radius: 3/2em;
    width: 14vw;
  }

  .menuDevis {
    margin-right: 1vw;
    margin-left: 4vw;
    font-weight: bold;
    font-size: 18px;
    background-color: transparent;
    border-radius: 3/2em;
    width: 14vw;
  }

  .headerrow {
    display: flex;
    position: relative;
    left: 70%;
    flex-direction: row;
    flex: 0.9;
  }

  .telimg {
    left: 257%;
    margin-top: -6px;
    width: 99%;
    max-height: 53px;
  }


  .middle {
    width: 56vw;
  }
}

@media (min-width: 1310px) and (max-width: 1800px) {
  .mainContainer {
    height: 70px;
  }

  .middle {
    padding-left: 0px;
  }

  .header {
    width: 100%;
    height: 80px;
  }

  .img {
    width: 114px;
    top: 2%;
    position: relative;
    z-index: 6;
  }

  .menu {
    margin-right: 3vw;
    margin-left: 4vw;
    font-weight: bold;
    font-size: 18px;
    border: 2px solid white;
    border-radius: 3/2em;
    width: 14vw;
  }

  .menuDevis {
    margin-right: 1vw;
    margin-left: 4vw;
    font-weight: bold;
    font-size: 18px;
    border-radius: 3/2em;
    width: 14vw;
  }

  .headerrow {
    padding-top: 10px;
    display: flex;
    padding-left: 161px;
    flex: 0.8;
    flex-direction: row;
    /* left: 14%; */
    position: relative;
  }

  .telimg {
    left: 257%;
    margin-top: -6px;
    width: 99%;
    max-height: 53px;
  }


  .middle {
    width: 56vw;
  }
}

@media (min-width: 1800px) and (max-width: 9999px) {
  .mainContainer {
    height: 70px;
  }

  .middle {
    padding-left: 0px;
  }

  .header {
    width: 100%;
    height: 80px;
  }

  .img {
    width: 114px;
    top: 2%;
    position: relative;
    z-index: 6;
  }

  .menu {
    margin-right: 3vw;
    margin-left: 9vw;
    font-weight: bold;
    font-size: 21px;
    border: 2px solid white;
    border-radius: 3/2em;
    width: 10vw;
  }

  .menuDevis {
    margin-right: 1vw;
    margin-left: 6vw;
    font-weight: bold;
    font-size: 21px;
    border-radius: 3/2em;
    width: 10vw;
  }

  .headerrow {
    display: flex;
    position: relative;
    top: 10px;
    align-items: flex-end;
    flex-direction: row;
    margin-left: 21%;
    flex: 0.9;
  }

  .telimg {
    left: 160%;
    margin-top: -6px;
    width: 99%;
    max-height: 53px;
  }

  .telimg:hover {
    border: 3px solid #044b88;
    color: #044b88;
    background-color: white;
  }

  .telimg.hovered {
    background: white;
    border: 3px solid #044b88;
    color: #044b88;
  }


  .middle {
    width: 56vw;
  }
}