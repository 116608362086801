.Card {
    background-color: white;
    display: flex;
    width: 98%;
    padding: 150px;
    border-radius: 20px;
    margin: 20px;
    justify-content: center;
}
.row{
    display: flex;
    flex-direction: row;
    text-align: center;
}
.imgContain{
    width: 120px;
    height: 120px;
    margin-left: 15px;
    margin-right: 15px;
    object-fit: contain;
}
.red{
    width: 50%;
}
.blue{
    width: 50%;
}
.LoginLabel {
    border-radius: 20px;
    text-align: center;
    margin: 25px;
    border: 10px solid;
    border-radius: 20px;
    border-color: #004b88;
    background-color: #004b88;

}
.label {
    color: white;
  }
.LoginLabel::placeholder {
    color: white;
}

.Signin {
    border: 4px solid;
    border-radius: 20px;
    font-weight: bold;
    background-color: white;
    color: #004b88;
    border-color: #004b88;
}