.center{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 96vh;
    padding: 10px;
}
.sizedefiner{
    min-height: 100%;
    max-height: 100%;
}
.imgselector{
    color: white;
    border: 2px solid white;
    border-radius: 1em;
    margin-left: 5px;
    margin-right: 5px;
    font-size: x-large;
    background-color: transparent;
}
.blanckbutton{
    background-color: white;
    color:  #044b88;
}
.vue{
    width: 55%;
    height: 30vh;
    position: relative;
    top: 14vh;
    max-height: 360px;
}
.reduce{
    margin:20px;
    max-width: 70%;
}
.Verticalseparator{
    width: 5%;
}
.reduceopen{
    margin: 0px;
    max-width: 100%;
    min-width: 62%;
    position: relative;
    top: 0vh;
}
.paddingTop{
height: 20vh;
padding: 10px;
}
.rowalign{
    display: flex;
    padding: 10px;
    flex-direction: row;
}
.leftText{
    display: flex;
    font-size: 22px;
    color: white;
    justify-content: center;
    width: 47vw;
    height: 61vh;
    text-align: center;
    align-items: center;
}
.network{
    max-height: 55vh;
}