body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  [class^="number-slide"],
  [class*=" number-slide"] {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    height: 65vh;
    max-height: 100vh;
  }
  
  .number-slide2 {
    background-image: url('./assets/pikist3.png');
    background-size: cover;
    background-position: center;
  }
  
  .number-slide1 {
    background-image: url('./assets/vue-generale-de-marseille_1920x600.jpg');
    background-size: cover;
    background-position: center;

  }
  
  .number-slide3 {
    background-image: url('./assets/csm_1920-telecoms-header_e2302bceea.webp');
    background-size: cover;
    background-position:right;
  }
  
  .number-slide4 {
    background-image: url('./assets/motivating-call-center-employees-jpg.jpg');
    background-size: cover;
    background-position:right;
  }
  
  .number-slide5 {
    background-image: url('./assets/yeah.png');
    background-color: #004b88;
    background-size: cover;
    background-position:top;
  }
  .number-slide6 {
    background: rgb(64, 76, 255);
    background: linear-gradient(
      128deg,
      rgba(64, 76, 255, 1) 0%,
      rgba(174, 63, 255, 1) 100%  
    );


  }
.complete{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #004b88;
}