.disaxes{
    margin-left: 15px;
    
    margin-top: -5px;
width: 100px;
height: 100px;
}
.homebutton{
    position: relative;
    z-index: 60;
    margin-left: 10px;
    margin-top: -5px;
 width: 110px;
 height: 100px;
}