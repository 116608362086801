h1 {
    font-family: LexendDecaMedium;
    color: #004B88;
    text-align: center;
  }
  h2 {
    font-family: LexendDecaMedium;
    color: #004B88;
    font-size: 23px;
    text-align: center;
  }
  h3 {
    font-family: LexendDeca;
    color: #0065B7;
    text-align: center;
    font-size: 23px;
  }
  h5 {
    font-family: LexendDeca;
    color: #0065B7;
    text-align: center;
    font-size: 19px;
  }
  .whitetext {
    color: white;
  }
  
  .widget-spe {
    background-color: rgb(255, 255, 255);
    position: relative;
  }
  
  .bluecard {
    background-color: #103556;
    position: relative;
  }
  
  .wht {
    color: white;
  }
  
  .imgmiddle {
    position: relative;
    max-width: 437px;
    max-height: 382px;
  }

  .padd {
    padding: 1px;
    margin: 10px;
    max-width: 437px;
    max-height: 382px;
  }
  
  .paadd {
    margin: 10px;
    width: 40%;
  }
  .tourwrapper{
    position: relative;
    left: 5vw;
    top: 1.5vh;
  }
  .openimg {
    width: 50%;
    object-fit:cover;
  }
  
  .deux {
    position: relative;
  }
  .spacing {
    height: 30px;
  }
  .spacing20 {
    margin-bottom: 20px;
  }
  .adjustx2 {
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .Vspacing {
    width: 25%;
  }
  
  .Vspacingspe {
    padding-top: 400px;
    height: 250px;
  }
  
  
  .thk {
    height: 190px;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  
  .thkalt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
  }
  
  .cln-container {
    display: flex;
    position: relative;
    flex: 1 1;
    bottom: 18%;
    height: 47vh;
    justify-content: space-between;
  }
  .cln,
  .clnalt,
  .clnalt2 {
    width: 33%; /* Each takes 33% of the available space */
    max-width: 33%; /* Maximum width is 33% of the page */
    display: flex;
    position: relative;
    top: 15%;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center; /* Adjust the margin for spacing */
  }
  .imgmiddle,
.padd,
.h1,
.h3 {
  margin: 10px 0;
  width: 100%; /* Add margin to separate text elements */
}
.imgmiddle, .padd, .padd.deux {
  width: 100%;
  display: flex;
}

  .clmn4text {
    flex-direction: column;
    position: relative;
    right: 5vw;
    align-items: center;
    max-width: 60vw;
  }
  
  
  .resz {
    height: 12vh;
    z-index: 2;
  }
  
  h5 {
    font-family: LexendDeca;
    color: #0065B7;
    size: 23px;
  }
  
  .icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 52vw;
    width: 100vw;
    max-width: 100%;
  }
  
  .ficon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 60vw;
    width: 100%;
    max-width: 100%;
  }
  
  .spe {
    width: 80px;
  }
  
  .logs {
    width: 67px;
  }
  
  .ico {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px;
    width: 33%;
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .pad {
    margin-top: 7px;
  }
  
  .anime {
    animation: animation 10s;
    animation-iteration-count: infinite;
  }
  
  .flow {
    position: absolute;
    z-index: 1;
    top: 140px;
    padding-top: 10vh;
    left: 35%;
    width: 30%;
    justify-content: center;
  }
  
  @media (min-width: 300px) and (max-width: 630px) {
    .icon {
      margin-left: 16vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-width: 52vw;
      width: 50vw;
      max-width: 100%;
    }
  }
  
  @keyframes animation {
    0% {
      width: 30%;
      opacity: 0;
    }
  
    50% {
      width: 30%;
      opacity: 0.7;
    }
  
    100% {
      width: 5%;
      opacity: 0;
    }
  }
  