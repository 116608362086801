.twoSide{
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
}
.book{
    width: 100%;
    padding: 25px;
}
.align{
    display: flex;
    flex-direction: row;
}
.ObjectSlctr{
 padding: 25px;
    border: 3px solid #004b88;
    background-color: #004b88;
    color: white;
    font-size: large;
    font-weight: bold;
    border-radius: 20px;
    margin: 5px;
}
.sfil{
    margin-left: 7%;
    width: 25%;}
.widget {
    background: rgb(255, 255, 255);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    text-align: center;
    position: relative;
    margin: 0px;
    height: 92%;
    padding: 0px;
    border-radius: 0px;
    width: 100%;
    overflow: hidden;
    z-index: 60;
}
.last {
    padding-left: 30%;}
.Carda{
    font-family: LexendDecaMedium;
    color: #004b88;
font-size: 21px;
height: 462px;
text-align: center; 
    align-items: center;
    border: solid 2px #004b88;
}
.arga{
    width: 100%;
    height: 100%;
}
.resz{
    display: flex;
    width: 11%;
    align-items: center;
}
@media (min-width: 300px) and (max-width: 337px) {
    .widget{width: 96vw;}
    .wave {
        top: 125.2vh;
        height: 8vh;
    }    
}