.ServiceCtnr {
    display: flex;
    flex-direction: column;
}
.Spacing{
    height: 100px;
}
.Title{
    font-size: 25px;
    font-weight: bold;
}
.max{
    padding: 5px;
    max-width: 700px;
    max-height: 1000px;
}
.rowalign{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}