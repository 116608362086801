
.footer {
  margin-top: auto; /* Push the footer to the bottom */
}

.main {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  padding-right: 0px;
  height: 20%;
  bottom: 0;/* Set background color for the footer */
}


.PageRest{ width: 80vh;}
.bimg {
  margin-top: 8%;
  margin-left: 8%;
  width: 50%;

}

.Header {
  font-size: 26px;
  font-weight: bold;
  width: 100%;
  max-width: 99vw;
  flex-direction: row;
  z-index: 4;
  ;
}

.Background {
  width: 100%;
  height: 27%;
  margin-top: 76vh;
  position: fixed;
  bottom: 0;
}

.Logo {
  padding-top: 6.5%;
  width: 20%;
}
.Sponsor{
  position: relative;
  top: 8vh;
  width: 54%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}
.clndisplay{
  display: flex;
  flex-direction: column;
  position: relative;
  top: 40px;
}
.verticalspacing{
  width: 10%;
}
.sponsorTitle{
  color: #044b88;
  font-size: large;
}
.alcaicon{max-width: 150px;}
.Clients{}
.yeahlinkicon{
  position: relative;
  top: 20px;
  width: 150px;
height: 50px;}

.Rest {
  justify-content: center;
  display: flex;
  position: relative;
  width: 30%;
  top: 95px;
  height: 60px;
}
.button-connect {
  font-family: 'Archivo Black', sans-serif;
  font-size: 20px;
  border-radius: 60px;
  height: 100%;
  margin-top: 37px;
  text-align: center;
}
@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.wave {
  background: rgb(255 255 255 / 25%);
  border-radius: 1000% 1000% 0 0;
  width: 100%;
  height: 100%;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  left: 0;
  position: absolute;
  bottom: 0;
  /* bottom: 33vh; */
  z-index: -1;
}

.wave:nth-of-type(2) {
  bottom: -1.14em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
  /* bottom: 33vh; */
}

.wave:nth-of-type(3) {
  bottom: -2.7em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
  /* bottom: 33vh; */
}

@media (min-width: 1500px) and (max-width: 9999px) { 
  .button-connect{
      margin-top: 10%;
      width: 50%;}
      .bimg {
        margin-top: 8%;
        margin-left: 8%;
        width: 30%;
      
      }
}
@media (min-width: 300px) and (max-width: 600px) { 
  .Logo{width: 30%;}
  .bimg{width: 100%;
    margin-top: 8%;}
  .button-connect{
    width: 70%;
    height: 3vh;}
    .Rest {
      margin-top: 0;
      margin-left: 2%;
    width: 55%;
}
}
@keyframes wave {
  2% {
    transform: translateY(1);
  }

  25% {
    transform: translateY(-5%);
  }

  50% {
    transform: translateY(-9%);
  }

  75% {
    transform: translateY(-15%);
  }

  100% {
    transform: translateY(1);
  }
}

