.twoSide{
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
}
.book{
    width: 100%;
    padding: 25px;
}
.main{  height: 20%;
    bottom: -150vh;}
.sfil{
    margin-left: 7%;
    width: 25%;}
.widget {
    background:rgb(255, 255, 255);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 9px;
    text-align: center;
    position: relative;
    padding:18px;
    overflow: hidden;
    z-index: 60;
    border-radius:20px;
}
.Vspacing{
    width: 10%;
}
.Cards{
    width: 80vw;
    height: 80vh;
    flex-direction: column;
    align-items: center;
    display: flex;}
.last {
    padding-left: 30%;}
.Carda{
    font-family: LexendDecaMedium;
    color: #004b88;
font-size: 21px;
height: 462px;
text-align: center; 
    align-items: center;
    border: solid 2px #004b88;
}
.arga{
    width: 100%;
    height: 100%;
}
.resz{
    display: flex;
    align-items: center;
    height :100%;
    overflow: scroll;
}
@media (min-width: 300px) and (max-width: 337px) {
    .widget{width: 96vw;}
    .wave {
        top: 125.2vh;
        height: 8vh;
    }    
}