
html ::-webkit-scrollbar {
  display: none;
}
section::-webkit-scrollbar{
  display: none;
}
.visible{animation: change 2s;}
.invisible{
animation: unchange 2s;}
@keyframes change {from{opacity: 0%;}
to{opacity: 100%;}
}
@keyframes unchange {
  from{opacity: 100%;}to{opacity: 0%;}
}
html{width: 100vw;}
body{ 
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
}
.Auth{
  opacity: 0.9;
}
@font-face{font-family: 'LexendDeca';
  src: URL('../public/LexendDeca-Light.ttf');}
  @font-face{font-family: 'LexendDecaMedium';
    src: URL('../public/LexendDeca-Medium.ttf');}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
h4{
  color: #003488;
  font-family: LexendDeca;
}
html {
  scroll-behavior: smooth;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
